import React, { Suspense } from 'react';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';

import { useGetStates } from '@/api/use-states';
import { Button } from '@/components-new/button';
import { ButtonBar } from '@/components-new/button-bar';
import { Checkbox, CheckboxField, CheckboxGroup } from '@/components-new/checkbox';
import { Label } from '@/components-new/fieldset';
import { SectionDescription, SectionHeading } from '@/components-new/section';
import { useUpdateUserSettings, useUserSettings } from '@/features/user-settings/api/api';
import { UserSetting, UserSettingsList } from '@/features/user-settings/components/user-setting';
import { UserSettingsNameField } from '@/features/user-settings/components/user-settings-name-field';
import { UserSettingsResetPassword } from '@/features/user-settings/components/user-settings-reset-password';
import { UserSettings } from '@/features/user-settings/types/user-settings';
import { notifyError, notifySuccess } from '@/lib/notification/notifications';

type PriorityStatesForm = {
  priorityStates: string[];
}

const UserGeneralSettingsPage = () => {
  const { data: userSettings } = useUserSettings();
  const { data: stateOptions, isLoading: isLoadingStates } = useGetStates();
  const { mutate: priorityStatesMutation, isPending: priorityStatesMutationIsPending } = useUpdateUserSettings();

  const {
    getValues,
    handleSubmit,
    control,
  } = useForm<PriorityStatesForm>();

  const onSubmit: SubmitHandler<PriorityStatesForm> = data => {
    priorityStatesMutation({ priorityStates: data.priorityStates } as UserSettings, {
      onSuccess: () => {
        notifySuccess({ title: 'Updated', message: 'Priority states have been updated.' });
      },
      onError: () => {
        notifyError({ title: 'Uh Oh', message: 'Priority states failed to update.' });
      }
    });
  };

  return (
    <div className="mt-6 space-y-12 lg:mx-0 lg:max-w-4xl">
      <div>
        <SectionHeading level={2}>Profile</SectionHeading>
        <SectionDescription>
          Account information.
        </SectionDescription>
        <Suspense>
          <UserSettingsList>
            <UserSetting title="Client">{userSettings.client}</UserSetting>
            <UserSetting title="Full name">
              <UserSettingsNameField fullName={userSettings.name}/>
            </UserSetting>
            <UserSetting title="Email address">{userSettings.email}</UserSetting>
            <UserSetting title="Password">
              <UserSettingsResetPassword/>
            </UserSetting>
          </UserSettingsList>
        </Suspense>
      </div>
      <div>
        <SectionHeading level={2}>Priority States</SectionHeading>
        <SectionDescription>
          Select the states relevant to your work.
        </SectionDescription>
        <Controller
          name="priorityStates"
          control={control}
          defaultValue={userSettings.priorityStates}
          render={({ field: { onChange } }) => {
            return (
              <CheckboxGroup className="mt-6 border-t pt-6 sm:columns-2 md:columns-3">
                {!isLoadingStates && stateOptions && stateOptions.map((stateOption) => {
                  return (
                    <CheckboxField key={stateOption.code}>
                      <Checkbox
                        defaultChecked={userSettings.priorityStates.includes(stateOption.code)}
                        value={stateOption.code}
                        onChange={(checked) => {
                          checked ?
                            onChange([...getValues('priorityStates'), stateOption.code]) :
                            onChange(getValues('priorityStates').filter((stateCode) => stateCode != stateOption.code));
                        }}
                      />
                      <Label>{stateOption.name}</Label>
                    </CheckboxField>
                  );
                })}
              </CheckboxGroup>
            );
          }}
        />

        <ButtonBar>
          <Button
            color="secondary"
            disabled={priorityStatesMutationIsPending}
            onClick={handleSubmit(onSubmit)}
          >Save</Button>
        </ButtonBar>
      </div>
    </div>
  );
};

export default UserGeneralSettingsPage;
