import { useMutation, useQuery, useQueryClient, useSuspenseQuery } from '@tanstack/react-query';

import { NotificationSetting } from '@/features/user-settings/types/notification-setting';
import { UserSettings } from '@/features/user-settings/types/user-settings';
import { artiaProtectedApi } from '@/lib/api-client';
import { queryClient } from '@/lib/react-query';

export const resetUserPassword = async (): Promise<void> => {
  await artiaProtectedApi.post('users/me/reset-password');
};

const getUserSettings = (): Promise<UserSettings> => {
  return artiaProtectedApi.get('user-settings');
};

export const useUserSettings = () => {
  return useSuspenseQuery({
    queryKey: ['user-settings'],
    queryFn: async () => getUserSettings()
  });
};

const updateUserSettings = async (userSettings: UserSettings): Promise<void> => {
  const response = await artiaProtectedApi.put('user-settings', {
    name: userSettings.name,
    priorityStates: userSettings.priorityStates
  });

  return response.data;
};

export const useUpdateUserSettings  = () => {
  return useMutation({
    mutationFn: (data: UserSettings) => updateUserSettings(data),
    onSuccess: () => {
      void queryClient.invalidateQueries({ queryKey: ['calendar'] });
      void queryClient.invalidateQueries({ queryKey: ['me'] });
      void queryClient.invalidateQueries({ queryKey: ['user-settings'] });
    }
  });
};

const getUserNotificationSettings = (): Promise<NotificationSetting[]> => {
  return artiaProtectedApi.get('user-settings/notifications');
};

export const useUserNotificationSettings = () => {
  return useQuery({
    queryKey: ['notification-settings'],
    queryFn: async () => await getUserNotificationSettings()
  });
};

export const useUpdateNotificationSettings = () => {
  const queryClient = useQueryClient();

  return useMutation({
      mutationFn: async (notificationSettings: NotificationSetting[]) => await artiaProtectedApi.put('user-settings/notifications', notificationSettings),
      onMutate: (notificationSettings) => {
        queryClient.setQueryData<NotificationSetting[]>(['notification-settings'], notificationSettings);
        return { notificationSettings };
      },
      onError: () => {
        return useUserNotificationSettings;
      }
    }
  );
};
