import React from 'react';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';

import { Button } from '@/components-new/button';
import { ButtonBar } from '@/components-new/button-bar';
import { Checkbox, CheckboxField, CheckboxGroup } from '@/components-new/checkbox';
import { Label } from '@/components-new/fieldset';
import { Radio, RadioField, RadioGroup } from '@/components-new/radio';
import { SectionDescription, SectionHeading } from '@/components-new/section';
import { useUpdateNotificationSettings, useUserNotificationSettings } from '@/features/user-settings/api/api';
import { UserSetting, UserSettingsList } from '@/features/user-settings/components/user-setting';
import { NotificationSetting } from '@/features/user-settings/types/notification-setting';
import { notifyError, notifySuccess } from '@/lib/notification/notifications';

type NotificationSettingsForm = {
  notificationSettings: NotificationSetting[];
}

const UserNotificationSettingsPage = () => {
  const { data: userNotificationSettings } = useUserNotificationSettings();
  const { mutate: mutateNotificationSettings } = useUpdateNotificationSettings();

  const {
    control,
    handleSubmit,
    setValue
  } = useForm<NotificationSettingsForm>();

  const onSubmit: SubmitHandler<NotificationSettingsForm> = (data) => {
    mutateNotificationSettings(data.notificationSettings, {
      onSuccess: () => {
        notifySuccess({ title: 'Success', message: 'Notification preferences updated.' });
      },
      onError: () => {
        notifyError({ title: 'Uh Oh', message: 'Notification preferences failed to update. Try again later.' });
      }
    });
  };

  return(
    <div className="mt-6 lg:mx-0 lg:max-w-3xl">
      <SectionHeading level={2}>Notifications</SectionHeading>
      <SectionDescription>
        Manage how you receive notifications.
      </SectionDescription>
      <UserSettingsList>
        {
          userNotificationSettings?.map((notificationSetting, index) => (
            <UserSetting key={index} title={notificationSetting.category}>
              <div className="flex-col space-y-6">
                <CheckboxGroup>
                  <Controller
                    name={`notificationSettings.${index}.categoryId`}
                    defaultValue={notificationSetting.categoryId}
                    control={control}
                    render={({ field }) => (<input name={field.name} type="hidden" value={field.value} />)}
                  />
                  <Controller
                    name={`notificationSettings.${index}.category`}
                    defaultValue={notificationSetting.category}
                    control={control}
                    render={({ field }) => (<input name={field.name} type="hidden" value={field.value} />)}
                  />
                  <Controller
                    name={`notificationSettings.${index}.receiveInCapsule`}
                    control={control}
                    defaultValue={notificationSetting.receiveInCapsule}
                    render={({ field }) => (
                      <CheckboxField>
                        <Checkbox
                          name={field.name}
                          checked={field.value}
                          onChange={(isChecked) => field.onChange(isChecked)}
                          //onChange={field.onChange}
                        />
                        <Label>Receive in Capsule</Label>
                      </CheckboxField>
                    )}
                  />
                   <Controller
                     name={`notificationSettings.${index}.receiveEmails`}
                     control={control}
                     defaultValue={notificationSetting.receiveEmails}
                     render={({ field }) => (
                       <CheckboxField>
                         <Checkbox
                           name={field.name}
                           checked={field.value}
                           onChange={(isChecked) => field.onChange(isChecked)}
                         />
                         <Label>Receive emails</Label>
                       </CheckboxField>
                     )}
                  />
                </CheckboxGroup>
                <Controller
                  name={`notificationSettings.${index}.priorityStatesOnly`}
                  control={control}
                  defaultValue={notificationSetting.priorityStatesOnly}
                  render={({ field }) => (
                    <RadioGroup className="flex-1"
                      name={field.name}
                      defaultValue={notificationSetting.priorityStatesOnly ? 'Priority' : 'All' }
                      onChange={(value) => setValue(field.name, value == 'Priority')}
                    >
                      <RadioField>
                        <Radio value="All" />
                        <Label>For all states</Label>
                      </RadioField>
                      <RadioField>
                        <Radio value="Priority" />
                        <Label>For priority states only</Label>
                      </RadioField>
                    </RadioGroup>
                  )}
                />
              </div>
            </UserSetting>
          ))
        }

      </UserSettingsList>
      <ButtonBar>
        <Button color="secondary" onClick={handleSubmit(onSubmit)}>Save</Button>
      </ButtonBar>
    </div>
  );

};
export default UserNotificationSettingsPage;
