import React, { useState } from 'react';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';

import { Button } from '@/components-new/button';
import { Input } from '@/components-new/input';
import { useUpdateUserSettings } from '@/features/user-settings/api/api';
import { UserSettings } from '@/features/user-settings/types/user-settings';
import { notifyError, notifySuccess } from '@/lib/notification/notifications';

type Props = {
  fullName: string;
}

type UserSettingsNameForm = {
  name: string
}

export const UserSettingsNameField = (props: Props) => {
  const [ isEditing, setIsEditing ] = useState(false);
  const [ fullName, setFullName ] = useState(props.fullName);
  const { mutate, isPending } = useUpdateUserSettings();

  const {
    handleSubmit,
    control
  } = useForm<UserSettingsNameForm>();

  const onSubmit: SubmitHandler<UserSettingsNameForm> = data => {
    mutate({ name: data.name } as UserSettings, {
      onSuccess: (data: void, variables: UserSettings) => {
        notifySuccess({ title: 'Updated', message: 'Name has been updated.' });
        setIsEditing(false);
        setFullName(variables.name);
      },
      onError: () => {
        notifyError({ title: 'Uh Oh', message: 'Name failed to update.' });
      },
    });
  };

  return(
    <>
      {isEditing ?
        <>
          <Controller
            name="name"
            control={control}
            rules={{ required: true }}
            defaultValue={fullName}
            render={({ field }) => <Input {...field} /> }
          />
          <Button plain disabled={isPending} onClick={() => setIsEditing(false)}>Cancel</Button>
          <Button color="secondary" disabled={isPending} onClick={handleSubmit(onSubmit)}>Save</Button>
        </>
        :
        <>
          <div>{fullName}</div>
          <Button color="secondary" onClick={() => setIsEditing(true)}>Update</Button>
        </>
      }
      </>
  );
};
